import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>RV Expertises</title>
                <meta name="description" content="RV Expertises" />
                <meta name="og:title" property="og:title" content="RV Expertises"></meta>
                <meta name="twitter:card" content="RV Expertises"></meta>
                <link rel="canonical" href="https://www.rvexpertises.fr/"></link>
                <meta property="og:image" content="" />
            </Helmet>
        </div>
    )
}

export default SEO
